var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "11"
    }
  }, [!_vm.isSales ? _c('b-card', [_c('b-form-group', {
    attrs: {
      "label": "Pilih Sales"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.salesOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.selectedSales,
      callback: function callback($$v) {
        _vm.selectedSales = $$v;
      },
      expression: "selectedSales"
    }
  })], 1)], 1) : _vm._e(), _c('form-tanggal', {
    on: {
      "submit": _vm.submit
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }